<script setup>

</script>

<template>
    <div class="spacer"></div>
</template>

<style lang="scss" scoped>
.spacer {
    height: 3rem;
    width: 100%;
}
</style>
